import * as React from "react";
import { NextPage, NextPageContext } from "next";
import NextErrorComponent, { ErrorProps as NextErrorProps } from "next/error";
import * as Sentry from "@sentry/nextjs";

export type ErrorPageProps = {
  hasGetInitialPropsRun: boolean;
  err: Error;
} & NextErrorProps;

export type ErrorProps = {
  isReadyToRender: boolean;
};

const MyError: NextPage<ErrorPageProps, ErrorProps> = ({
  statusCode,
  hasGetInitialPropsRun,
  err,
}) => {
  if (!hasGetInitialPropsRun && err) {
    Sentry.captureException(err);
  }

  return <NextErrorComponent statusCode={statusCode} />;
};

MyError.getInitialProps = async (ctx): Promise<any> => {
  const errorInitialProps = (await NextErrorComponent.getInitialProps(
    ctx
  )) as NextPageContext & ErrorPageProps;

  if (ctx.res?.statusCode === 404) {
    return errorInitialProps;
  }

  if (ctx.err) {
    Sentry.captureException(ctx.err);
    await Sentry.flush(2000);

    return errorInitialProps;
  }

  await Sentry.flush(2000);

  return errorInitialProps;
};

export default MyError;
